import React from "react"
import Features from "../components/Features"
import Hero from "../components/Hero"
import Partners from "../components/Partners"
import Reviews from "../components/Reviews"
import About from "../components/About"
import HowItWorks from "../components/HowItWorks"
import Models from "../components/Models"
import Migration from "../components/Migration"
import Seo from "../components/SEO"

import heroImg from "../images/privatecloudserver.png"

export default function Gen1Page() {
    return (
        <div>
            <Seo title="Private Cloud in a Box" />
            <Hero
                image={heroImg}
                gradient="bg-gradient-to-tr from-blue-300 to-blue-500"
                title="Private Cloud in a Box"
                heading="Built to optimize your DevOps."
                text="Get set up in 5 minutes, launch any VPS in 10 seconds and save 75%+ over the Public Cloud."
            >
                <a href="#" className="btn">
                    Start Configuring Now
                </a>
            </Hero>
            <Partners />
            <About />
            <HowItWorks />
            <Models />
            <Reviews />
            <Migration />
            <Features />
        </div>
    )
}
