import React from "react"

import xdImg from "../images/xd.png"
import xdUltraImg from "../images/xdultra.png"

const models = [
  {
    image: xdImg,
    name: "Antsle one XD",
    price: "from $1,499",
    features: [
      "edgeLinux & antMan",
      "Intel Xeon-D",
      "4 Cores / 8 vCPUs",
      "up to 32 GB ECC RAM",
      "up to 5 TB storage",
      "M.2 SSD for ultra-fast cache",
      "10 GbE LAN",
    ],
  },
  {
    image: xdUltraImg,
    name: "Antsle one XD Ultra",
    price: "from $4,499",
    features: [
      "edgeLinux & antMan",
      "Intel Xeon-D",
      "12 Cores / 24 vCPUs",
      "up to 128 GB ECC RAM",
      "up to 16 TB storage",
      "M.2 SSD for ultra-fast cache",
      "10 GbE LAN",
    ],
  },
]

export default function Models() {
  return (
    <section className="container py-10 md:py-20">
      <h1 className="text-3xl font-bold text-center md:text-5xl">
        Our Most Popular Models
      </h1>
      <div className="flex flex-col items-center justify-center space-y-10 md:space-y-0 md:space-x-10 md:flex-row">
        {models.map((item, i) => (
          <div key={i} className="flex flex-col items-center">
            <img className="w-3/4 h-auto" src={item.image} alt={item.name} />
            <div className="pb-5 text-center border-b">
              <p className="text-3xl font-semibold text-gray-800 md:text-4xl">
                {item.name}
              </p>
              <p className="text-xl italic opacity-50 md:text-2xl">
                {item.price}
              </p>
            </div>
            <ul className="py-10 space-y-2 text-xl cardList">
              {item.features.map((f, i) => (
                <li>{f}</li>
              ))}
            </ul>
            <a href="https://antsle.com/buy" className="btn-red">
              Configure & Buy
            </a>
          </div>
        ))}
      </div>
    </section>
  )
}
