import React from "react"

import migrationImg1 from "../images/migration1.png"
import migrationImg2 from "../images/migration2.png"

const items = [
  {
    image: migrationImg1,
    title: "Import your Images",
    description:
      "Migrate from your current solution by simply importing your existing images via drag & drop.",
  },
  {
    image: migrationImg2,
    title: "Heavy Loads on Minimal Hardware",
    description:
      "Your instances can be full virtual machines or insanely efficient bare-metal containers.",
  },
]

export default function Migration() {
  return (
    <section className="py-10 bg-gray-50 md:py-20">
      <div className="container">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-800 md:text-5xl">
            Migration is Easy
          </h1>
          <p className="mt-2 md:text-xl">
            Coming from another cloud provider? It's super easy and we'll help
            as well!
          </p>
        </div>
        <div className="flex flex-col items-center justify-between pt-10 space-y-10 md:space-y-0 md:flex-row">
          {items.map((item, i) => (
            <div
              key={i}
              className="flex flex-col items-center justify-center space-y-5"
            >
              <img className="w-20 h-auto" src={item.image} alt={item.title} />
              <p className="text-2xl font-bold text-gray-800">{item.title}</p>
              <p className="text-center md:w-1/2">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
